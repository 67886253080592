import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Toast } from 'antd-mobile';
import { requiresAuth, loginRoute, defaultRoute } from '../../config/routes';

interface AuthGuardProps {
    children: React.ReactNode;
}

export const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
    const { isAuthenticated, checkAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const verifyAuth = async () => {
            if (isAuthenticated && (location.pathname === '/login' || location.pathname === '/register')) {
                navigate(defaultRoute, { replace: true });
                return;
            }

            if (!requiresAuth(location.pathname)) {
                return;
            }

            const isValid = await checkAuth();
            if (!isValid) {
                Toast.show({
                    content: '请先登录',
                });
                navigate(loginRoute, {
                    state: { from: location.pathname }
                });
            }
        };

        verifyAuth();
    }, [location.pathname, isAuthenticated]);

    if (requiresAuth(location.pathname) && !isAuthenticated) {
        return null;
    }

    return <>{children}</>;
}; 
import { createContext, useContext, useState, useEffect } from 'react';
import { User, AuthState } from '../types/auth';
import request from '../utils/request';

interface AuthContextType extends AuthState {
    login: (token: string, user: User) => void;
    logout: () => void;
    checkAuth: () => Promise<boolean>;
}

interface AuthCheckResponse {
    code: number;
    message: string;
    data?: {
        is_logged_in: boolean;
        user: User;
    };
}

// 创建认证上下文
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// 创建认证Hook
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

// 创建认证Provider Hook
export const useAuthProvider = () => {
    const [user, setUser] = useState<User | null>(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    const [token, setToken] = useState<string | null>(() => {
        return localStorage.getItem('token');
    });

    const isAuthenticated = Boolean(user && token);

    // 登录
    const login = (newToken: string, newUser: User) => {
        localStorage.setItem('token', newToken);
        localStorage.setItem('user', JSON.stringify(newUser));
        setToken(newToken);
        setUser(newUser);
    };

    // 登出
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setToken(null);
        setUser(null);
    };

    // 检查认证状态
    const checkAuth = async () => {
        try {
            const savedToken = localStorage.getItem('token');
            if (!savedToken) {
                logout();
                return false;
            }

            const response = await request.get<AuthCheckResponse>('/auth/check');
            console.log('Auth check response:', response); // 调试日志

            if (response.code === 0 && response.data?.is_logged_in) {
                setToken(savedToken);
                setUser(response.data.user);
                return true;
            } else {
                logout();
                return false;
            }
        } catch (error) {
            console.error('Auth check failed:', error);
            logout();
            return false;
        }
    };

    // 初始检查认证状态
    useEffect(() => {
        checkAuth();
    }, []);

    return {
        user,
        token,
        isAuthenticated,
        login,
        logout,
        checkAuth
    };
}; 
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import { AuthProvider } from 'components/AuthProvider';
import { AuthGuard } from 'components/AuthGuard';
import { routes, defaultRoute } from 'config/routes';

// 加载占位组件
const PageLoading = () => (
  <div style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh' 
  }}>
    加载中...
  </div>
);

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <AuthProvider>
        <Router>
          <Suspense fallback={<PageLoading />}>
            <Routes>
              {/* 根路由重定向 */}
              <Route path="/" element={
                <AuthGuard>
                  <Navigate to={defaultRoute} replace />
                </AuthGuard>
              } />

              {/* 动态生成路由 */}
              {routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <AuthGuard>
                      <route.component />
                    </AuthGuard>
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </Router>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App; 
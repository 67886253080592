import { lazy } from 'react';

// 页面组件
const Home = lazy(() => import('pages/Home'));
const Tasks = lazy(() => import('pages/Tasks'));
const Subjects = lazy(() => import('pages/Subjects'));
const Homework = lazy(() => import('pages/Homework'));
const Statistics = lazy(() => import('pages/Statistics'));
const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const Profile = lazy(() => import('pages/Profile'));
const Course = lazy(() => import('pages/Course'));

// 路由类型定义
export interface RouteConfig {
    path: string;           // 路由路径
    component: any;         // 组件
    title: string;          // 页面标题
    isPublic?: boolean;     // 是否公开访问
    icon?: string;          // 图标（可选）
    children?: RouteConfig[]; // 子路由
}

// 路由配置
export const routes: RouteConfig[] = [
    // 公开路由
    {
        path: '/login',
        component: Login,
        title: '登录',
        isPublic: true
    },
    {
        path: '/register',
        component: Register,
        title: '注册',
        isPublic: true
    },
    
    // 受保护路由
    {
        path: '/home',
        component: Home,
        title: '首页'
    },
    {
        path: '/courses',
        component: Course,
        title: '作业课'
    },
    {
        path: '/tasks',
        component: Tasks,
        title: '任务管理'
    },
    {
        path: '/subjects',
        component: Subjects,
        title: '学科管理'
    },
    {
        path: '/homework',
        component: Homework,
        title: '作业批改'
    },
    {
        path: '/statistics',
        component: Statistics,
        title: '作业统计'
    },
    {
        path: '/profile',
        component: Profile,
        title: '个人中心'
    }
];

// 默认重定向路由
export const defaultRoute = '/home';

// 登录页面路由
export const loginRoute = '/login';

// 获取所有受保护的路由路径
export const getProtectedPaths = (): string[] => {
    const paths: string[] = [];
    const extractPaths = (routeList: RouteConfig[]) => {
        routeList.forEach(route => {
            if (!route.isPublic) {
                paths.push(route.path);
            }
            if (route.children) {
                extractPaths(route.children);
            }
        });
    };
    extractPaths(routes);
    return paths;
};

// 检查路径是否需要认证
export const requiresAuth = (path: string): boolean => {
    const route = routes.find(r => r.path === path);
    return route ? !route.isPublic : true; // 默认需要认证
}; 
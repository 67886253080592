import axios, { AxiosRequestConfig, AxiosHeaders } from 'axios';

// 获取 API 基础 URL
const getBaseURL = () => {
  // 如果定义了环境变量，则使用环境变量
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  // 开发环境默认使用 localhost
  if (process.env.NODE_ENV === 'development') {
    return 'http://192.168.1.3:5000/api';
  }
  // 生产环境使用相对路径，这样会自动匹配当前域名
  return '/api';
};

const instance = axios.create({
  baseURL: getBaseURL(),
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 添加 token
    const token = localStorage.getItem('token');
    console.log('Token from localStorage:', token); // 调试日志
    
    if (token && config.headers) {
      (config.headers as AxiosHeaders).set('Authorization', `Bearer ${token}`);
      console.log('Authorization header set:', `Bearer ${token}`); // 调试日志
    }
    
    console.log('Request config:', {
      url: config.url,
      method: config.method,
      headers: config.headers,
      data: config.data
    });  // 调试日志
    
    return config;
  },
  (error) => {
    console.error('Request error:', error);  // 调试日志
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    console.log('Response:', {
      status: response.status,
      data: response.data,
      headers: response.headers
    });  // 调试日志
    return response.data;
  },
  (error) => {
    // 统一处理错误
    if (error.response?.status === 401) {
      // 如果是未登录错误，可以在这里处理（比如跳转到登录页）
      console.log('Unauthorized, redirecting to login...'); // 调试日志
      window.location.href = '/login';
    }
    console.error('Response error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });  // 调试日志
    return Promise.reject(error.response?.data || error);
  }
);

type RequestResponse<T> = Promise<T>;

const request = {
  get: <T>(url: string, config?: AxiosRequestConfig): RequestResponse<T> => 
    instance.get<any, T>(url, config),
  
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig): RequestResponse<T> => 
    instance.post<any, T>(url, data, config),
  
  put: <T>(url: string, data?: any, config?: AxiosRequestConfig): RequestResponse<T> => 
    instance.put<any, T>(url, data, config),
  
  delete: <T>(url: string, config?: AxiosRequestConfig): RequestResponse<T> => 
    instance.delete<any, T>(url, config),
};

export default request; 